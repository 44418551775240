import React, { Component, Fragment }  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Auth } from 'aws-amplify';
import Modal from 'react-modal'; // Import the modal component

export default class ProductAdmin extends Component {

  state = {
    isEditMode: false,
    updatedproductname: this.props.name,
    isAuthenticated: false,
    isAuthenticating: true,
    showLoginPrompt: false
  }

  handleProductEdit = event => {
    event.preventDefault();
    this.setState({ isEditMode: true });
  }

  handleEditSave = event => {
    event.preventDefault();
    this.setState({ isEditMode: false });
    this.props.handleUpdateProduct(this.props.id, this.state.updatedproductname);
  }
  
  checkAuthState = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      this.setAuthStatus(true);
    } catch (error) {
      console.error('User is not authenticated:', error);
      this.setAuthStatus(false);
      this.showLoginPrompt(); // Show login prompt dialog if user is not authenticated
    }
    this.setState({ isAuthenticating: false });
  };

  setAuthStatus = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  };

  showLoginPrompt = () => {
    this.setState({ showLoginPrompt: true });
  };

  hideLoginPrompt = () => {
    this.setState({ showLoginPrompt: false });
  };


  onAddProductNameChange = event => this.setState({ "updatedproductname": event.target.value });
  componentDidMount = () => {
    this.checkAuthState();
  }
  render() {
    const { isAuthenticated, isAuthenticating, showLoginPrompt } = this.state;
    return (
      <Fragment>
        {isAuthenticated ? (
            <div className="tile is-child box notification is-success">
              {
                this.props.isAdmin && 
                <Fragment>
                  <a href="/" onClick={this.handleProductEdit} className="product-edit-icon">
                    <FontAwesomeIcon icon="edit" />
                  </a>
                  <button onClick={event => this.props.handleDeleteProduct(this.props.id, event)} className="delete"></button>
                </Fragment>
              }
              {
                this.state.isEditMode 
                ? <div>
                    <p>Edit product name</p>
                    <input 
                      className="input is-medium"
                      type="text" 
                      placeholder="Enter name"
                      value={this.state.updatedproductname}
                      onChange={this.onAddProductNameChange}
                    />
                    <p className="product-id">id: { this.props.id }</p>
                    <button type="submit" 
                      className="button is-info is-small"
                      onClick={ this.handleEditSave }
                    >save</button>
                  </div>
                : <div>
                    <p className="product-title">{ this.props.name }</p>
                    <p className="product-id">id: { this.props.id }</p>
                  </div>
              }
            </div>
    ) : null}

     {/* Login Required Dialog Box */}
     <Modal isOpen={showLoginPrompt}  contentLabel="Login Required">
              <h2>Login Required</h2>
              <p>Please login to continue viewing out list of products.</p>
              <button onClick={() => { window.location.href = '/login'; }}>Sign In</button>
            </Modal> 
    </Fragment>
    )
  }
}
