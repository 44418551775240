import React from 'react'

export default function Footer() {
  return (
    <footer className="footer">
      <div className="content has-text-centered">
        <p>
           This is a Demo Web Page for Awsome Builder
        </p>
      </div>
    </footer>
  )
}
