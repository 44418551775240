import React, { Component, Fragment } from 'react';
import Product from './Product';
import axios from "axios";
import { Auth } from 'aws-amplify';
import Modal from 'react-modal'; // Import the modal component
const config = require('../config.json');


export default class Products extends Component {

  state = {
    newproduct: null,
    products: [],
    isAuthenticated: false,
    isAuthenticating: true,
    showLoginPrompt: false
  }

  fetchProducts = async () => {
    // add call to AWS API Gateway to fetch products here
    // then set them in state

    try {
      const headers = {
        "Authorization": "Bearer " + (await Auth.currentSession()).getIdToken().getJwtToken()
      };
      console.log(headers);
      const res = await axios.get(`${config.api.invokeUrl}/products`,{headers});
      console.log(res);
      const products = res.data;
        this.setState({products: products});
    }catch (err) {
      console.log(`An error has occurred: ${err}`)
    }
  }

  checkAuthState = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      this.setAuthStatus(true);
    } catch (error) {
      console.error('User is not authenticated:', error);
      this.setAuthStatus(false);
      this.showLoginPrompt(); // Show login prompt dialog if user is not authenticated
    }
    this.setState({ isAuthenticating: false });
  };

  setAuthStatus = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  };

  showLoginPrompt = () => {
    this.setState({ showLoginPrompt: true });
  };

  hideLoginPrompt = () => {
    this.setState({ showLoginPrompt: false });
  };

  componentDidMount = () => {
    this.checkAuthState();
    this.fetchProducts();
  }

  render() {
    const { isAuthenticated, isAuthenticating, showLoginPrompt } = this.state;
    return (
      <Fragment>
        {isAuthenticated ? (
            <section className="section">
              <div className="container">
                <h1>Energy Products</h1>
                <p className="subtitle is-5">Invest in a clean future with our efficient and cost-effective green energy products:</p>
                <br />
                <div className="columns">
                  <div className="column">
                    <div className="tile is-ancestor">
                      <div className="tile is-4 is-parent  is-vertical">
                        { 
                          this.state.products && this.state.products.length > 0
                          ? this.state.products.map(product => <Product name={product.productname} id={product.id} key={product.id} />)
                          : <div className="tile notification is-warning">No products available</div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : null}
        {/* Login Required Dialog Box */}
        <Modal isOpen={showLoginPrompt}  contentLabel="Login Required">
              <h2>Login Required</h2>
              <p>Please login to continue viewing our list of products.</p>
              <button onClick={() => { window.location.href = '/login'; }}>Sign In</button>
        </Modal> 
      </Fragment>
    )
  }
}
