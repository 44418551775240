import React, { Component, Fragment } from 'react';
import Product from './Product';
import './dialogStyles.css';
import axios from "axios";
import { Auth } from 'aws-amplify';
import Modal from 'react-modal'; // Import the modal component
const config = require('../config.json');


export default class ProductAdmin extends Component {

    state = {
        newproduct: {
            "productname": "",
            "id": ""
        },
        products: [],
        isAuthenticated: false,
        isAuthenticating: true,
        isAdmin: false,
        user: null,
        showLoginPrompt: false // Added state for login prompt dialog box
    }



    handleAddProduct = async(id, event) => {
        event.preventDefault();
        // add call to AWS API Gateway add product endpoint here
        try {
            const params = {
                "id": id,
                "productname": this.state.newproduct.productname
            };

            const headers = {
              "Authorization": "Bearer " + (await Auth.currentSession()).getIdToken().getJwtToken()
            };
            await axios.post(`${config.api.invokeUrl}/products/${id}`, params, {headers});
            this.setState({ products: [...this.state.products, this.state.newproduct] });
            this.setState({ newproduct: { "productname": "", "id": "" } });
        } catch (err) {
            console.log(`An error has occurred: ${err}`);
        }
    }

    handleUpdateProduct = async(id, name) => {
        // add call to AWS API Gateway update product endpoint here
        try {
            const params = {
                "id": id,
                "productname": name
            };
            const headers = {
              "Authorization": "Bearer " + (await Auth.currentSession()).getIdToken().getJwtToken()
            };
            await axios.patch(`${config.api.invokeUrl}/products/${id}`, params,{headers});
            const productToUpdate = [...this.state.products].find(product => product.id === id);
            const updatedProducts = [...this.state.products].filter(product => product.id !== id);
            productToUpdate.productname = name;
            updatedProducts.push(productToUpdate);
            this.setState({ products: updatedProducts });
        } catch (err) {
            console.log(`Error updating product: ${err}`);
        }
    }

    checkAuthState = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        this.setAuthStatus(true);
      } catch (error) {
        console.error('User is not authenticated:', error);
        this.setAuthStatus(false);
        this.showLoginPrompt(); // Show login prompt dialog if user is not authenticated
      }
  
      this.setState({ isAuthenticating: false });


      // Now check for Admin group claim
      //const decodedToken = parseJwt(session.getIdToken().getJwtToken());
      const session = await Auth.currentSession();
      const decodedToken = JSON.parse(decodeURIComponent(window.atob(session.getIdToken().getJwtToken().split('.')[1].replace(/-/g, '+').replace(/_/g, '/')).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join('')));

      // Check if the 'cognito:groups' claim exists and its value is 'ProductAdmins'
      if (
        decodedToken &&
        decodedToken['cognito:groups'] &&
        decodedToken['cognito:groups'][0] === 'ProductAdmins'
      ) {
        this.setAdminClaimStatus(true); // Token has the required claim and value
      }
      else {
        this.setAdminClaimStatus(false);
        this.showLoginPrompt(); // Token does not have the required claim or value
      }
    };

    setAdminClaimStatus = claim_bool => {
      this.setState({ isAdmin: claim_bool });
      console.log(this.state);
    };

    setAuthStatus = authenticated => {
      this.setState({ isAuthenticated: authenticated });
    };

    showLoginPrompt = () => {
      this.setState({ showLoginPrompt: true });
    };

    hideLoginPrompt = () => {
      this.setState({ showLoginPrompt: false });
    };
    

    handleDeleteProduct = async(id, event) => {
        event.preventDefault();
        // add call to AWS API Gateway delete product endpoint here
        try {
            const headers = {
              "Authorization": "Bearer " + (await Auth.currentSession()).getIdToken().getJwtToken()
            };
            await axios.delete(`${config.api.invokeUrl}/products/${id}`,{headers});
            const updatedProducts = [...this.state.products].filter(product => product.id !== id);
            this.setState({ products: updatedProducts });
        } catch (err) {
            console.log(`Unable to delete product: ${err}`);
        }
    }

    fetchProducts = async() => {
        // add call to AWS API Gateway to fetch products here
        // then set them in state
        try {
            const headers = {
              "Authorization": "Bearer " + (await Auth.currentSession()).getIdToken().getJwtToken()
            };
            const res = await axios.get(`${config.api.invokeUrl}/products`,{headers});
            
            const products = res.data;
            this.setState({ products: products });
        } catch (err) {
            console.log(`An error has occurred: ${err}`);
        }
    }

    onAddProductNameChange = event => this.setState({ newproduct: {...this.state.newproduct, "productname": event.target.value } });
    onAddProductIdChange = event => this.setState({ newproduct: {...this.state.newproduct, "id": event.target.value } });

    componentDidMount = () => {
      this.checkAuthState();
      this.fetchProducts();  
    }


    render() {
        console.log(this.state);
        const { isAuthenticated, isAuthenticating, isAdmin, showLoginPrompt } = this.state;

        return (
          <Fragment>
            {isAdmin ? (
                  <section className="section">
                  <div className="container">
                    <h1>Product Admin</h1>
                    <p className="subtitle is-5">Add and remove products using the form below:</p>
                    <br />
                    <div className="columns">
                      <div className="column is-one-third">
                        <form onSubmit={event => this.handleAddProduct(this.state.newproduct.id, event)}>
                          <div className="field has-addons">
                            <div className="control">
                              <input 
                                className="input is-medium"
                                type="text" 
                                placeholder="Enter name"
                                value={this.state.newproduct.productname}
                                onChange={this.onAddProductNameChange}
                              />
                            </div>
                            <div className="control">
                              <input 
                                className="input is-medium"
                                type="text" 
                                placeholder="Enter id"
                                value={this.state.newproduct.id}
                                onChange={this.onAddProductIdChange}
                              />
                            </div>
                            <div className="control">
                              <button type="submit" className="button is-primary is-medium">
                                Add product
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="column is-two-thirds">
                        <div className="tile is-ancestor">
                          <div className="tile is-4 is-parent  is-vertical">
                            { 
                              this.state.products.map((product, index) => 
                                <Product 
                                  isAdmin={true}
                                  handleUpdateProduct={this.handleUpdateProduct}
                                  handleDeleteProduct={this.handleDeleteProduct} 
                                  name={product.productname} 
                                  id={product.id}
                                  key={product.id}
                                />)
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
            ) : null}

            {/* Login Required Dialog Box */}
            <Modal isOpen={showLoginPrompt}  contentLabel="Login Required">
              <h2>Admin Login Required</h2>
              <p>Please login as an Administrator to continue viewing this page.</p>
              <button onClick={() => { window.location.href = '/login'; }}>Sign In</button>
            </Modal> 

          </Fragment>
        )
      }
    }